<template>
  <scrollable-container>
    <placeholder
      v-show="state === reportEnum.requestAwaiting"
      :text="text"
      @buttonClick="buildReport"
    />
    <loader v-show="state === reportEnum.tableLoading" />
    <container
      v-show="state === reportEnum.tableReady"
      id="printable"
      :class="[
        { [$style.message]: state === reportEnum.requestAwaiting },
        $style.scrollableContent,
      ]"
    >
      <div
        v-if="tableData.length === 0"
        :class="$style.emptyResult"
      >
        Нет данных за указанный период
      </div>

      <div
        v-for="(user, index) in tableData"
        :key="user.name"
        :class="$style.user"
      >
        <div
          :class="$style.userStickyToggle"
          @click="toggle(user)"
        >
          <div :class="[
            $style.expandButton,
            { [$style.expanded]: user.isExpanded },
          ]"
          />
          <div :class="$style.name">
            {{ user.name }}
          </div>
        </div>

        <worktime-table
          v-for="date in user.dates"
          v-show="user.isExpanded"
          :key="user.name + date.date + index"
          :class="$style.userData"
          :date="date"
        />
      </div>
    </container>
  </scrollable-container>
</template>

<script>
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';
import Container from '@/components/common/blocks/container.vue';
import Placeholder from '@/components/common/blocks/report-placeholder.vue';
import { getWorktimeTable } from '@/api/methods/reports/users/index';
import Loader from '@/components/common/blocks/loader.vue';
import WorktimeTable from './worktime-table.vue';
import { getDataField } from './table-field';

export default {
  components: {
    ScrollableContainer,
    Container,
    Placeholder,
    WorktimeTable,
    Loader,
  },
  data: () => ({
    name: 'Табель рабочего времени',
    tableData: [],
    computersArray: [],
    state: 2,
    reportEnum: Object.freeze({
      tableReady: 1,
      requestAwaiting: 2,
      tableLoading: 3,
    }),
    text: 'Для построения отчета нажмите кнопку',
    columns: [
      { name: 'Включен', field: 'workTime' },
      { name: 'Активность', field: 'activeTime' },
      { name: 'Простой', field: 'idleTime' },
      { name: '% активности', field: 'activePercent' },
      { name: 'Начало', field: 'dayStart' },
      { name: 'Окончание', field: 'dayFinish' },
      { name: 'График', field: 'isWorkingDay' },
      { name: 'По плану', field: 'plan' },
      { name: 'Переработка', field: 'overwork' },
    ],
  }),
  computed: {
    dateRange() {
      return this.$store.getters['filters/formattedDateRange'];
    },
    checkedComputers() {
      return this.$store.getters['pageSpecificData/checkedComputersList'];
    },
    computersTree() {
      const tree = this.$store.getters['pageSpecificData/computersTree'];

      return tree;
    },
    isWorktimeOnly() {
      return this.$store.getters['filters/isWorktimeOnly'];
    },
  },
  watch: {
    dateRange(oldValue, newValue) {
      if (
        oldValue.dateFrom.toString() !== newValue.dateFrom.toString()
        || oldValue.dateTo.toString() !== newValue.dateTo.toString()
      ) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
    checkedComputers(oldArray, newArray) {
      if (oldArray !== newArray) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
    isWorktimeOnly(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
  },
  methods: {
    toggle(user) {
      user.isExpanded = !user.isExpanded;
    },
    getCheckedComputersFromTree(node) {
      if (node.type === 'computer' && node.checked) this.computersArray.push(node);
      if (node.type === 'folder') {
        node.children.forEach((child) => this.getCheckedComputersFromTree(child));
      }
    },
    buildReport() {
      this.state = this.reportEnum.tableLoading;
      this.tableData = [];
      let workTimeOnly = 0;
      this.computersArray = [];

      if (this.computersTree) {
        this.computersTree.forEach((node) => this.getCheckedComputersFromTree(node));
      }

      if (
        this.dateRange.dateFrom
        && this.dateRange.dateTo
        && this.computersArray
      ) {
        if (this.isWorktimeOnly) workTimeOnly = 1;

        this.promise = getWorktimeTable(
          this.computersArray,
          this.dateRange.dateFrom,
          this.dateRange.dateTo,
          workTimeOnly,
        ).then(({ data }) => {
          if (data) {
            this.tableData = data.result;

            this.state = this.reportEnum.tableReady;

            const exportArray = [];

            for (let i = 0; i < data.result.length; i++) {
              const { name } = data.result[i];
              for (let x = 0; x < data.result[i].dates.length; x++) {
                const { date } = data.result[i].dates[x];
                const resObj = { Пользователь: name, Дата: date };
                for (let j = 0; j < this.columns.length; j++) {
                  resObj[this.columns[j].name] = getDataField(
                    data.result[i].dates[x][this.columns[j].field],
                    this.columns[j].field,
                  );
                }
                exportArray.push(resObj);
              }
            }

            this.$store.commit('exportData/setWorktime', { name: this.name, data: exportArray });
          } else this.state = this.reportEnum.requestAwaiting;

          return data;
        });
      }
    },
  },
};
</script>

<style lang="scss" module>
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $branchColor;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.scrollableContent {
  margin: 0 8px 0 0;
  padding: 0 12px 0 20px;
  box-shadow: $shadow;
  flex: 1;
}

.message {
  display: flex;
  justify-content: center;
  align-items: center;
}

.userData {
  margin-left: 30px;
  position: relative;
}

.name {
  font-weight: 500;
  display: inline-flex;
  background-color: #f5f5f5;
  width: 100%;
  align-items: center;
  padding: 5px 10px;
  margin-right: 12px;
  font-size: 14px;
}

.userStickyToggle {
  position: sticky;
  z-index: 3;
  top: -1px;
  width: 100%;
  line-height: 14px;
  cursor: pointer;
  margin-left: 12px;
  align-items: center;
  display: inline-flex;
}

.expandButton {
  flex-shrink: 0;
  margin-left: -27px;
  margin-right: 11px;
  width: 14px;
  height: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $branchColor;
  background: $white;
  z-index: 2;

  &::before,
  &::after {
    content: '';
    background-color: $blue;
    width: 8px;
    height: 2px;
    position: absolute;
    transition: 0.25s ease-out;
  }

  &::before {
    transform: rotate(90deg);
  }

  &::after {
    transform: rotate(180deg);
  }

  &.expanded {
    &::before {
      transform: rotate(0);
    }

    &::after {
      transform: rotate(0);
    }
  }
}

.user {
  margin: 5px;
}

.emptyResult {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.table {
  width: 100%;
  margin-bottom: 15px;
  border-collapse: collapse;
  font-weight: 400;
  word-break: break-word;
  border: 1px solid $borderColor;
  padding-bottom: 20px;
}

.header {
  background: #dbf1ff;
  height: 31px;
  position: sticky;
  top: -2px;
  z-index: 2;

  td {
    border: 1px solid $borderColor;
    align-items: center;
    text-align: center;
    color: #232326;
    letter-spacing: -0.08px;
    font-size: 13px;
    padding: 7px 4px 4px 10px;
    cursor: default;
  }
}
</style>
